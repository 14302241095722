import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { userApiBaseUrl } from "../urlProvider.api";
import { listUrl, post } from "../../model/intefaceUtilisateur";
import { ResponseFetch } from "../responseFectch";

// import {MessageResponse} from "../../model/interfaceResponse";

//configuratoin du crud d'un user
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: ResponseFetch(userApiBaseUrl()),
  tagTypes: ["user"],
  endpoints: function (builder) {
    return {
      //Operation GET
      getUser: builder.query<listUrl, void>({
        query: () => ({
          url: "user/get",
          mode: "cors",
        }),
        providesTags: ["user"],
      }),

      //Operation GET
      getAlertPosts: builder.query<
        {
          url: string;
          posts: post[];
        },
        string
      >({
        query: (value) => ({
          url: `url/posts/get?url=${value}`,
          mode: "cors",
        }),

        providesTags: ["user"],
      }),

      //Operation POST Login
      postUrl: builder.mutation<void, { user: string; url: string }>({
        query: (value: { url: string }) => ({
          url: "url/post",
          method: "POST",
          mode: "cors",
          body: value,
        }),
        invalidatesTags: ["user"],
      }),
      //Operation delete Login
      deleteUrl: builder.mutation<void, { user: string; url: string }>({
        query: (value: { url: string }) => ({
          url: "url/delete",
          method: "DELETE",
          mode: "cors",
          body: value,
        }),
        invalidatesTags: ["user"],
      }),
    };
  },
});

export const {
  useGetAlertPostsQuery,
  usePostUrlMutation,
  useGetUserQuery,
  useDeleteUrlMutation,
} = userApi;
