import { CheckIcon } from "@heroicons/react/24/outline";
import { Loading } from "../Loading";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props {
  prices: any;
  stripeId: string;
}
export default function PricingPlan(props: Props) {
  const createSubscription = async (priceId: string) => {
    const session = await fetch("https://api.bot-vinted.com/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stripeId: props.stripeId,
        priceId,
      }),
    }).then((r) => r.text());
    // console.log(session);
    window.location.replace(session);
    // setSubscriptionData(session);
  };
  const prices = props.prices;

  return (
    <div>
      {prices.length > 0 ? (
        <div className="mx-auto max-w-7xl bg-white py-1 px-6 lg:px-8 ">
          <h2
            className="text-3xl flex justify-center font-bold tracking-tight sm:text-2xl sm:leading-none lg:text-4xl"
            style={{
              color: "#2F1C81",
            }}
          >
            Tarifs du Bot
          </h2>
          <div className="flex justify-center">
            <p className="mt-3 max-w-2xl text-center text-gray-500 ">
              Que vous soyez débutant ou expert sur le resell Vinted, vous
              pouvez démarrer avec notre Bot et évoluer au fur et à mesure.
            </p>
          </div>

          {/* Tiers */}
          <div className="mt-4 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
            {prices.map((price: any) => (
              <div
                key={price.id}
                className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm  price-list"
              >
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-900  price-content">
                    {price.title}
                  </h3>
                  {price.mostPopular ? (
                    <p
                      // style={{ backgroundColor: "#b18cfe" }}
                      className="bg-violet absolute top-0 -translate-y-1/2 transform rounded-full py-1.5 px-4 text-sm font-semibold text-white price-content"
                    >
                      Le plus populaire
                    </p>
                  ) : null}
                  <p className="mt-4 flex items-baseline text-gray-900 price-content">
                    <span className="text-3xl font-bold tracking-tight">
                      {price.price}€
                    </span>
                    <span className="ml-1 text-xl font-semibold">
                      {price.frequency}
                    </span>
                  </p>
                  {/* Feature list */}
                  {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                  <ul role="list" className="mt-6 space-y-6">
                    {price.features.map((feature: string) => (
                      <li key={feature} className="flex">
                        <CheckIcon
                          className="h-6 w-6 flex-shrink-0 text-indigo-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-gray-500 price-content">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  onClick={() => createSubscription(price.id)}
                  className={classNames(
                    price.mostPopular
                      ? "bg-violet text-white hover:bg-indigo-600"
                      : "bg-indigo-50 text-indigo-700 hover:bg-indigo-100",
                    "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                  )}
                >
                  {price.cta}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : prices.length === 1 ? (
        <div>Erreur</div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
