import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { post } from "../../model/intefaceUtilisateur";

interface urlState {
  list: string[];
  alertList: post[];
  // listId: string[];
}

const initialState: urlState = {
  list: [],
  alertList: [],
  // listId: [],
};

//reducer du url

export const urlSlice = createSlice({
  name: "url",
  initialState,
  reducers: {
    //url
    setUrlList: (
      state: { list: urlState["list"] },
      action: PayloadAction<urlState["list"]>
    ) => {
      state.list = action.payload;
    },
    //alerte liste
    // setAlertList: (
    //   state: { alertList: urlState["alertList"] },
    //   action: PayloadAction<urlState["alertList"]>
    // ) => {
    //   state.alertList = action.payload;
    // },
    // updateList: (
    //   state: { alertList: urlState["alertList"] },
    //   action: PayloadAction<post>
    // ) => {
    //   state.alertList.unshift(action.payload);
    // },
    // deleteElementAfter200: (state: { alertList: urlState["alertList"] }) => {
    //   state.alertList.splice(200);
    // },
    //
    // setAddPost: (
    //   state: { alertList: urlState["alertList"] },
    //   action: PayloadAction<urlState["alertList"]>
    // ) => {
    //   //filtrer une liste  en vérifiant si l'id est déjà dans la liste
    //   // state.alertList.filter((post) => {
    //   //   return action.payload.map((post2) => {
    //   //     return post.post_id !== post2.post_id
    //   //   }).filter((bol) => bol === false)[0] === false ? false : true
    //   // });
    //
    //   state.alertList.forEach((post) => {
    //     action.payload.forEach((post2) => {
    //       if (post.post_id !== post2.post_id) {
    //         state.alertList.unshift(post2);
    //       }
    //     });
    //   });
    // },
    //
    // sortListByData: (state: { alertList: urlState["alertList"] }) => {
    //   state.alertList = state.alertList.sort((x, y) => {
    //     return (
    //       new Date(y.post_time.toString()).getTime() -
    //       new Date(x.post_time.toString()).getTime()
    //     );
    //   });
    // },
    //list id
    // setListId: (
    //   state: { listId: urlState["listId"] },
    //   action: PayloadAction<string[]>
    // ) => {
    //   state.listId = action.payload;
    // },
    // updateListId: (
    //   state: { listId: urlState["listId"] },
    //   action: PayloadAction<string>
    // ) => {
    //   state.listId.unshift(action.payload);
    // },
    // deleteIdsAfter100: (state: { listId: urlState["listId"] }) => {
    //   state.listId.splice(100);
    // },
  },
});

export const urlActions = urlSlice.actions;
export const urlReducer = urlSlice.reducer;
