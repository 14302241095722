/* This example requires Tailwind CSS v3.0+ */
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: `Que veut dire "Vous n'avez pas mis un lien Vinted valide ou sans paramètres (catalog[])" ?`,
    answer:
      "Vous n'avez pas sélectionné de catégorie de produit dans votre recherche. Cliquez au minimum sur Catégorie->Homme/Femme->Tous pour que votre URL soit acceptée",
  },
  {
    question: "Pourquoi je n'ai pas d'articles dans mon feed ?",
    answer:
      "Les articles sont supprimés de la base de données toutes les 15 minutes, c'est la raison pour laquelle il n'y a pas d'historique, car les produits que vous cherchez sont vendu au delà de ce délais",
  },
  {
    question: `Les produits viennent d'apparaitre dans mon feed marqué comme "il y a 4 minutes" pourquoi ?`,
    answer: `Le bot est instantané, la raison pour laquelle le temps du post n'est pas de quelques secondes, c'est parce que Vinted met en ligne les produits par vague chaque 5 minutes. 
      Par exemple, si un produit est mis en vente a 15h01 et un autre à 15h05, les deux seront publiés sur Vinted via la même vague. `,
  },
  {
    question: "Le nombre de place est-il limité ?",
    answer: `Oui bien-sûr, pour éviter que tous nos utilisateurs se marchent dessus, nous limitons l'usage du BOT à un 4500 URLs (tout plan compris)`,
  },
  {
    question: "Avez-vous un programme d'affiliation ?",
    answer:
      "Oui, vous pouvez nous contacter par mail et nous vous ajouterons au programme",
  },
  {
    question: "Quel est le délais de votre BOT ?",
    answer: `Notre BOT a un délais de 2,2 secondes, mais les vagues de produits sont publiées chaque 5 minutes, donc certains produits sont parfois crawlés en 1 seconde mais sont marqués comme "Il y a 4 minutes"`,
  },
  // More questions...
];

export default function FaqComponent() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-4 sm:py-32 lg:py-40 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Questions fréquemment posées
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
