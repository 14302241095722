import React, { useEffect, useState } from "react";
// import { withRouter } from 'react-router-dom';
import { useGetUserQuery } from "../../api/user/user.api";
import { userConnexion } from "../../App";
import ErrorPage from "../../pages/ErrorPage";
import { Loading } from "../Loading";
import PricingPlan from "./PricingPlan";
import Dashboard from "./MaterioDashboard";

// import { Navigate } from "react-router-dom";

const DashboardComponsant = () => {
  const [prices, setPrices] = useState<any>([]);
  // const [subscriptionData, setSubscriptionData] = useState({});
  // const [message, setMessage] = useState("");

  //Get List Url
  const { data, error, isLoading, refetch } = useGetUserQuery();

  const features = [
    [
      "5 URLs de recherches",
      "Notification en 2,2 secondes",
      "Support Réactif",
      "Accès 24h/24h",
      "Sans engagement",
    ],
    [
      "15 URLs de recherches",
      "Notification en 2,2 secondes",
      "Support Réactif",
      "Accès 24h/24h",
      "Sans engagement",
    ],
    [
      "50 URLs de recherches",
      "Notification en 2,2 secondes",
      "Support Réactif",
      "Accès 24h/24h",
      "Sans engagement",
    ],
  ];

  useEffect(() => {
    if (data !== undefined) {
      localStorage.setItem(
        "userConnexion",
        JSON.stringify({
          ...userConnexion,
          plan: data.plan,
        })
      );
    }
  }, [data]);
  useEffect(() => {
    fetch("https://api.bot-vinted.com/config")
      .then((r) => r.json())
      .then((data) =>
        setPrices(
          data.prices.reverse().map((price: any, index: number) => {
            return {
              id: price.id,
              title: price.product.name,
              price: price.unit_amount / 100,
              frequency: "/mois",
              features: features[index],
              cta: "Démarrer",
              mostPopular: index === 1,
            };
          })
        )
      )
      .catch((e) => setPrices([e]));
    // console.log(publishableKey, prices);
  }, [1]);

  // JSX.Element
  const displayPayment = (): JSX.Element => {
    if (data)
      return (
        <>
          {data.plan === "none" ? (
            <div>
              <PricingPlan prices={prices} stripeId={data.stripeId} />
            </div>
          ) : (
            <div className={"flex flex-col"}>
              <Dashboard data={data} />
            </div>
          )}
        </>
      );
    else if (isLoading) return <Loading />;
    else if (error)
      return (
        <ErrorPage message={"...Oups, Il y'a une erreur😥"} refresh={refetch} />
      );
    else return <></>;
  };

  return <div className={"price-Container"}>{displayPayment()}</div>;
};

export default DashboardComponsant;
