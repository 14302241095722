import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import SideBar from "../composants/sidebar/SideBar";
import { Outlet } from "react-router-dom";

import botVintedImg from "../images/navBar/Logo-Bot-Vinted.png";
import UserDropdown from "../composants/lien/UserDropdown";
import NotificationDropdown from "../composants/lien/NotificationDropdown";
import { backgroundTheme } from "../App";

function Home(): JSX.Element {
  // const { settings, saveSettings } = props;
  return (
    <div className={"alerteContainer"}>
      <Navbar
        fixed={"top"}
        className={"navbarContainer"}
        style={{
          background: backgroundTheme,
          color: "rgba(0,0,0,.5)",
        }}
      >
        <NavbarBrand
          href="/"
          style={{
            marginLeft: "40px",
          }}
        >
          <img
            src={botVintedImg}
            alt={"botvintedImg"}
            style={{
              width: "110px",
              height: "45px",
            }}
          />
        </NavbarBrand>
        <NavbarBrand>
          <div className="nav-right">
            {/*<NotificationDropdown />*/}
            <UserDropdown />
          </div>
        </NavbarBrand>
      </Navbar>

      <div className={"contentAlerte"}>
        <SideBar //showsidebaronclick={}
        />

        <div
          className="siderbarElement"
          style={{
            background:
              backgroundTheme.localeCompare("#eaecf0") === 0 ? "white" : "",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Home;
