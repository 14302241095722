import React, {createContext, useState} from "react";

export const AccordionContext = createContext({
    active: 0,
    setActive: (index: number) => {
    },
});

interface Props {
    children: React.ReactNode | JSX.Element[];
}

/*le context recupère chaque objet de la list avec la clé correspondante
* ce qui nous permet de savoir sur quel box exactement la coulour doit etrre
* quand on clique */
const AccordionWrapper = (props: Props) => {
    const [active, setActive] = useState<number>(0);

    return (
        <AccordionContext.Provider
            value={{
                active,
                setActive,
            }}
        >
            <div>
                <form>{props.children}</form>
            </div>
        </AccordionContext.Provider>
    );
};

export default AccordionWrapper;
