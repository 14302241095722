import React from 'react';
import tree from "../../images/connexion/tree-2.png";
import tree1 from "../../images/connexion/tree-3.png";
import InscriptionComponent from "../../composants/auth/InscriptionComponent";

const Inscription = () => {
    return (
        <div className={"connexionpageContainer"}>
            <img src={tree} alt={"tree"} className={"connexionImage1"}/>
            <InscriptionComponent/>
            <img src={tree1} alt={"tree1"} className={"connexionImage2"}/>
        </div>
    );
};

export default Inscription;