import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { utilisateurInscription } from "../../model/intefaceUtilisateur";

interface UserState {
  isAuthenticated: boolean;
  userInfo: utilisateurInscription;
  isAnimating: boolean;
  urlClick: string;
  notificationAlert: boolean;
  //  emailForgotMdp: string;
}

const initialState: UserState = {
  isAuthenticated: !!localStorage.getItem("tokenBotVinted"),
  isAnimating: false,
  userInfo: {
    username: "",
    email: "",
    password: "",
  },
  notificationAlert: false,
  urlClick: "",
  // emailForgotMdp: ''
};

//reducer du user

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAuthenticated: (
      state: { isAuthenticated: boolean },
      action: PayloadAction<boolean>
    ) => {
      state.isAuthenticated = action.payload;
    },

    updateUserInfo: (
      state: { userInfo: UserState["userInfo"] },
      action: PayloadAction<UserState["userInfo"]>
    ) => {
      state.userInfo = action.payload;
    },
    setAnimate: (
      state: { isAnimating: UserState["isAnimating"] },
      action: PayloadAction<UserState["isAnimating"]>
    ) => {
      state.isAnimating = action.payload;
    },
    setUrlClick: (
      state: { urlClick: UserState["urlClick"] },
      action: PayloadAction<UserState["urlClick"]>
    ) => {
      state.urlClick = action.payload;
    },
    setnotificationAlert: (
      state: { notificationAlert: UserState["notificationAlert"] },
      action: PayloadAction<UserState["notificationAlert"]>
    ) => {
      state.notificationAlert = action.payload;
    },

    // setEmailForgotMdp: (
    //     state: { emailForgotMdp: UserState['emailForgotMdp'] },
    //     action: PayloadAction<UserState['emailForgotMdp']>
    // ) => {
    //     state.emailForgotMdp = action.payload;
    // },
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
