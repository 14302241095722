//Configurations des url de l'api
export const provideApiBaseUrl = () => {
    if (process.env.NODE_ENV === "development") {
        return process.env.REACT_APP_API_BASE_URL;
    } else if (process.env.NODE_ENV === "production") {
        return process.env.REACT_APP_API_BASE_URL;
    }
};

//user
export const userApiBaseUrl = () => {
    return provideApiBaseUrl() + "/api";
};

//auth
export const authApiBaseUrl = () => {
    return provideApiBaseUrl() + "/api/auth";
};
