import React, { useContext, useEffect } from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link, useLocation } from "react-router-dom";

import { AccordionContext } from "../AccordionWrapper";
import { useAppSelector } from "../../redux/customHooks";
import { store } from "../../redux/store";
import { userActions } from "../../redux/slices/userSlice";

interface Props {
  index: number;
  subcategories: any[];
  categorie: string;
  route: string;
  icon: React.ReactNode;
  //  onclick: () => void;
}

function CategoriesList(props: Props) {
  const { active, setActive } = useContext(AccordionContext);

  const location = useLocation();
  const isAnimating = useAppSelector((state) => state.user.isAnimating);
  useEffect(() => {
    if (location.pathname === "/" + props.route) setActive(props.index);
  }, [location, props.index, props.route, setActive]);
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div
            className={
              active === props.index
                ? "active accordion-item-custom"
                : "inactive accordion-item-custom"
            }
            id={"divfocus"}
            onClick={() => {
              setActive(props.index);
              if (props.subcategories.length === 0)
                store.dispatch(userActions.setAnimate(!isAnimating));
            }}
          >
            <Link to={`/${props.route}`}>
              <div className="accordion-item-content">
                <div className={"categoriesNameandImg"}>
                  {props.icon}
                  <div
                    className={"categoriesName"}
                    data-testid={"categoriesName"}
                  >
                    {props.categorie}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="accordion-custom-panel">
        {props.subcategories.map((sub) => (
          <div
            key={sub.key}
            onClick={() => {
              //   props.onclick()
            }}
            className={"accordion-custom-panel-content"}
          >
            <Link className="link" to={`/${props.route}/${sub.route}`}>
              <div className={"cercle"} />
              <div className="subName">{sub.subcategorie}</div>
            </Link>
          </div>
        ))}
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export default CategoriesList;
