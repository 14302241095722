export const pathGlobal = {
  auth: {
    connexion: "",
    inscription: "inscription",
  },
  url: {
    dashboard: "",
    lien: "lien",
    compte: "compte",
  },
  alerte: {
    alerte: "alerte",
    alerteTous: "alerte-tous",
  },
  faq: {
    faq: "faq",
  }
};
