import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  utilisateurConnexion,
  utilisateurInscription,
} from "../../model/intefaceUtilisateur";
import { authApiBaseUrl } from "../urlProvider.api";

// import {MessageResponse} from "../../model/interfaceResponse";

interface userResponse {
  id: string;
  username: string;
  email: string;
  accessToken: string;
}

//configuratoin du crud d'un user
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: authApiBaseUrl(),
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("tokenBotVinted") as string;
      // By default, if we have a token in the store, let's use that for authenticated requests
      if (token) headers.set("x-access-token", token);

      return headers;
    },
  }),
  tagTypes: ["auth"],
  endpoints: function (builder) {
    return {
      //Operation POST Login
      postSignin: builder.mutation<userResponse, utilisateurConnexion>({
        query: (value: utilisateurConnexion) => ({
          url: "signin",
          method: "POST",
          body: value,
          mode: "cors",
        }),
        transformResponse: (response: userResponse, meta, arg) => {
          if (
            ("#" + Math.random().toString(16).slice(-3)).toUpperCase() !==
            "#FFFFFF"
          )
            localStorage.setItem(
              "colorContainer",
              "#" + Math.random().toString(16).slice(-3)
            );
          localStorage.setItem(
            "tokenBotVinted",
            response.accessToken as string
          );

          if (response && typeof response === "object")
            //   sessionStorage.setItem("userIdentifiantConnexion", response.email[0]);
            localStorage.setItem(
              "userConnexion",
              JSON.stringify({
                username: response.username,
                email: response.email,
                plan: "",
              })
            );
          //  store.dispatch(userActions.updateUserInfo(response))
          return response;
        },
        invalidatesTags: ["auth"],
      }),

      //Operation POST Signin
      postSignup: builder.mutation<userResponse, utilisateurInscription>({
        query: (value: utilisateurInscription) => ({
          url: "signup",
          method: "POST",
          body: value,
          mode: "cors",
        }),
        transformResponse: (response: userResponse, meta, arg) => {
          localStorage.setItem(
            "tokenBotVinted",
            response.accessToken as string
          );
          if (
            ("#" + Math.random().toString(16).slice(-3)).toUpperCase() !==
            "#FFFFFF"
          )
            localStorage.setItem(
              "colorContainer",
              "#" + Math.random().toString(16).slice(-3)
            );
          if (response && typeof response === "object")
            //    sessionStorage.setItem("userIdentifiantConnexion", response.email[0]);
            localStorage.setItem(
              "userConnexion",
              JSON.stringify({
                username: response.username,
                email: response.email,
                plan: "",
              })
            );
          //store.dispatch(userActions.updateUserInfo(response.value))
          return response;
        },
        invalidatesTags: ["auth"],
      }),
    };
  },
});

export const { usePostSigninMutation, usePostSignupMutation } = authApi;
