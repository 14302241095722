import React from "react";
import AlerteComposant from "../../composants/alerteLien/AlerteComposant";

const Alerte = () => {
  return (
    <div>
      <AlerteComposant />
    </div>
  );
};

export default Alerte;