import { IoIosEye, IoIosNotificationsOutline } from "react-icons/io";
import { pathGlobal } from "../../RouteName";
import { HiOutlineHome } from "react-icons/hi";
import { FaQuestionCircle } from "react-icons/fa";

export const CATEGORIES = [
  {
    key: 1,
    categorie: "Dashboard",
    route: pathGlobal.url.dashboard,
    icon: (
      <HiOutlineHome
        size={28}
        // onClick={() => setValuesUrl(1)}
        // style={{
        //     color: valuesUrl === 1 ? "rgba(0, 119, 130, 1)" : "",
        // }}
      />
    ),
    subcategories: [
      // {
      //     "key": 1,
      //     "subcategorie": "Tous les produits",
      //     "route": "tous les produits"
      // },
      // {
      //     "key": 2,
      //     "subcategorie": "Produits ajoutés dans favoris",
      //     "route": "produits ajoutes dans favoris"
      // }
    ],
  },
  {
    key: 2,
    categorie: "Liens",
    route: pathGlobal.url.lien,
    icon: (
      <IoIosNotificationsOutline
        size={28}
        // onClick={() => setValuesUrl(1)}
        // style={{
        //     color: valuesUrl === 1 ? "rgba(0, 119, 130, 1)" : "",
        // }}
      />
    ),
    subcategories: [
      // {
      //     "key": 1,
      //     "subcategorie": "Tous les produits",
      //     "route": "tous les produits"
      // },
      // {
      //     "key": 2,
      //     "subcategorie": "Produits ajoutés dans favoris",
      //     "route": "produits ajoutes dans favoris"
      // }
    ],
  },
  {
    key: 3,
    categorie: "Alertes",
    route: pathGlobal.alerte.alerteTous,
    icon: (
      <IoIosEye
        size={28}
        // onClick={() => setValuesUrl(1)}
        // style={{
        //     color: valuesUrl === 1 ? "rgba(0, 119, 130, 1)" : "",
        // }}
      />
    ),
    subcategories: [
      // {
      //     "key": 1,
      //     "subcategorie": "Tous les produits",
      //     "route": "tous les produits"
      // },
      // {
      //     "key": 2,
      //     "subcategorie": "Produits ajoutés dans favoris",
      //     "route": "produits ajoutes dans favoris"
      // }
    ],
  },
  {
    key: 4,
    categorie: "FAQ",
    route: "faq",
    icon: (
      <FaQuestionCircle
        size={25}
        // style={{
        //     color: valuesUrl === 2 ? "rgba(0, 119, 130, 1)" : "",
        // }}
      />
    ),
    subcategories: [],
  },
];
