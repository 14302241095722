import React from "react";
import { Container } from "reactstrap";

const ErrorPage = (props: { message: string; refresh: () => void }) => {
  return (
    <Container
      fluid
      className="notFound"
      // style={{
      //   height: window.innerHeight,
      // }}
    >
      <div
        className="notFoundContent"
        style={
          {
            //    marginTop: window.innerHeight / 2 - 100
          }
        }
      >
        <h3>
          {props.message ? props.message : "Désolé cette page n'existe pas!🤔"}
        </h3>
      </div>
      {/*<Link to={"/"}>*/}
      <button
        className={"linkHome"}
        onClick={(e) => {
          e.preventDefault();
          props.refresh();
        }}
      >
        {/*<FontAwesomeIcon icon={faHome} className={'icon'}/>*/}
        <div>Réessayez</div>
      </button>
      {/*</Link>*/}
    </Container>
  );
};

export default ErrorPage;
