// ** MUI Imports
import Grid from "@mui/material/Grid";

// ** Icons Imports
import Poll from "mdi-material-ui/Poll";
// import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import HelpCircleOutline from "mdi-material-ui/HelpCircleOutline";
import BriefcaseVariantOutline from "mdi-material-ui/BriefcaseVariantOutline";

// ** Custom Components Imports
import CardStatisticsVerticalComponent from "./CardStatisticsVerticalComponent";

// ** Styled Component Import
import ApexChartWrapper from "./ApexChartWrapper";

// ** Demo Components Imports
// import Trophy from "src/views/dashboard/Trophy";
// import StatisticsCard from "src/views/dashboard/StatisticsCard";

// -------------StatisticsCard-------------------
import { ReactElement } from "react";
import trophyimg from "../../images/Dashboard/trophy.png";
import trianglelight from "../../images/Dashboard/triangle-light.png";
import triangledark from "../../images/Dashboard/triangle-dark.png";

// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";

// ** Icons Imports
import TrendingUp from "mdi-material-ui/TrendingUp";
import CellphoneLink from "mdi-material-ui/CellphoneLink";
import AccountOutline from "mdi-material-ui/AccountOutline";

import { listUrl } from "../../model/intefaceUtilisateur";
import { Link } from "react-router-dom";
import { pathGlobal } from "../../RouteName";

// ** Types
type ThemeColor = string;
// type ThemeColor =
// | "primary"
// | "secondary"
// | "error"
// | "warning"
// | "info"
// | "success";

// const DefaultPalette = (mode: PaletteMode, themeColor: ThemeColor) => {
//   // ** Vars
//   const lightColor = '58, 53, 65'
//   const darkColor = '231, 227, 252'
//   const mainColor = mode === 'light' ? lightColor : darkColor

//   const primaryGradient = () => {
//     if (themeColor === 'primary') {
//       return '#C6A7FE'
//     } else if (themeColor === 'secondary') {
//       return '#9C9FA4'
//     } else if (themeColor === 'success') {
//       return '#93DD5C'
//     } else if (themeColor === 'error') {
//       return '#FF8C90'
//     } else if (themeColor === 'warning') {
//       return '#FFCF5C'
//     } else {
//       return '#6ACDFF'
//     }
//   }

//   return {
//     customColors: {
//       main: mainColor,
//       primaryGradient: primaryGradient(),
//       tableHeaderBg: mode === 'light' ? '#F9FAFC' : '#3D3759'
//     },
//     common: {
//       black: '#000',
//       white: '#FFF'
//     },
//     mode: mode,
//     primary: {
//       light: '#9E69FD',
//       main: '#9155FD',
//       dark: '#804BDF',
//       contrastText: '#FFF'
//     },
//     secondary: {
//       light: '#9C9FA4',
//       main: '#8A8D93',
//       dark: '#777B82',
//       contrastText: '#FFF'
//     },
//     success: {
//       light: '#6AD01F',
//       main: '#56CA00',
//       dark: '#4CB200',
//       contrastText: '#FFF'
//     },
//     error: {
//       light: '#FF6166',
//       main: '#FF4C51',
//       dark: '#E04347',
//       contrastText: '#FFF'
//     },
//     warning: {
//       light: '#FFCA64',
//       main: '#FFB400',
//       dark: '#E09E00',
//       contrastText: '#FFF'
//     },
//     info: {
//       light: '#32BAFF',
//       main: '#16B1FF',
//       dark: '#139CE0',
//       contrastText: '#FFF'
//     },
//     grey: {
//       50: '#FAFAFA',
//       100: '#F5F5F5',
//       200: '#EEEEEE',
//       300: '#E0E0E0',
//       400: '#BDBDBD',
//       500: '#9E9E9E',
//       600: '#757575',
//       700: '#616161',
//       800: '#424242',
//       900: '#212121',
//       A100: '#D5D5D5',
//       A200: '#AAAAAA',
//       A400: '#616161',
//       A700: '#303030'
//     },
//     text: {
//       primary: `rgba(${mainColor}, 0.87)`,
//       secondary: `rgba(${mainColor}, 0.68)`,
//       disabled: `rgba(${mainColor}, 0.38)`
//     },
//     divider: `rgba(${mainColor}, 0.12)`,
//     background: {
//       paper: mode === 'light' ? '#FFF' : '#312D4B',
//       default: mode === 'light' ? '#F4F5FA' : '#28243D'
//     },
//     action: {
//       active: `rgba(${mainColor}, 0.54)`,
//       hover: `rgba(${mainColor}, 0.04)`,
//       selected: `rgba(${mainColor}, 0.08)`,
//       disabled: `rgba(${mainColor}, 0.3)`,
//       disabledBackground: `rgba(${mainColor}, 0.18)`,
//       focus: `rgba(${mainColor}, 0.12)`
//     }
//   }
// }

interface DataType {
  stats: string;
  title: string;
  color: ThemeColor;
  icon: ReactElement;
}

const user_plan: {} = {
  none: 0,
  cancelled: 5,
  "Reseller Débutant": 5,
  "Reseller Confirmé": 15,
  "Crack du Resell": 50,
};

const RenderStats = ({ data }: { data: listUrl }) => {
  // const { data, error, isLoading, refetch } = useGetUserQuery();

  const present_date = new Date();
  let remaining_days = 0;
  let current_plan;
  if (data.endDate !== null && data.endDate !== undefined) {
    remaining_days =
      (new Date(data.endDate).getTime() - present_date.getTime()) /
      (1000 * 3600 * 24);
  }
  if (data.plan !== undefined) {
    Object.entries(user_plan).find(([key, value]) => {
      if (key === data.plan) {
        current_plan = value;
        return true;
      }
      return false;
    });
  }

  const salesData: DataType[] = [
    {
      stats: `${data.urls.length}/${current_plan}`,
      title: "URLs",
      color: "#9460ff",
      icon: <TrendingUp sx={{ fontSize: "1.75rem" }} />,
    },
    {
      stats: data.plan.toUpperCase(),
      title: "Abonnement",
      color: "#9460ff",
      icon: <AccountOutline sx={{ fontSize: "1.75rem" }} />,
    },
    {
      stats: `${remaining_days.toFixed(0)}`,
      color: "#9460ff",
      title: "Jours Restants",
      icon: <CellphoneLink sx={{ fontSize: "1.75rem" }} />,
    },
  ];
  return salesData.map((item: DataType, index: number) => (
    <Grid item xs={12} sm={4} key={index}>
      <Box key={index} sx={{ display: "flex", alignItems: "top" }}>
        <Avatar
          variant="rounded"
          sx={{
            mr: 3,
            width: 44,
            height: 44,
            boxShadow: 3,
            color: "#fff",
            backgroundColor: "#9460ff",
          }}
        >
          {item.icon}
        </Avatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{item.title}</Typography>
          <Typography variant="h6" fontSize={18}>
            {item.stats}
          </Typography>
        </Box>
      </Box>
    </Grid>
  ));
};

const StatisticsCard = ({ data }: { data: listUrl }) => {
  return (
    <Card>
      <CardHeader
        title="Informations du Compte"
        // action={
        //   <IconButton
        //     size="small"
        //     aria-label="settings"
        //     className="card-more-options"
        //     sx={{ color: "text.secondary" }}
        //   >
        //     <DotsVertical />
        //   </IconButton>
        // }
        // subheader={
        //   <Typography variant="body2">
        //     <Box
        //       component="span"
        //       sx={{ fontWeight: 600, color: "text.primary" }}
        //     >
        //       Total 48.5% growth
        //     </Box>{" "}
        //     😎 this month
        //   </Typography>
        // }
        titleTypographyProps={{
          sx: {
            lineHeight: "1rem !important",
            letterSpacing: "0.15px !important",
          },
        }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(3)} !important` }}>
        <Grid container spacing={[5, 0]}>
          {RenderStats({ data })}
        </Grid>
      </CardContent>
    </Card>
  );
};

//  ------------Trophy--------------

// Styled component for the triangle shaped background image
const TriangleImg = styled("img")({
  right: 0,
  bottom: 0,
  height: 170,
  position: "absolute",
});

// Styled component for the trophy image
const TrophyImg = styled("img")({
  right: 36,
  bottom: 20,
  height: 98,
  position: "absolute",
});

const Trophy = ({ data }: { data: listUrl }) => {
  // ** Hook
  const theme = useTheme();

  const imageSrc =
    theme.palette.mode === "light" ? trianglelight : triangledark;

  return (
    <Card sx={{ position: "relative" }}>
      <CardContent style={{ width: "100%" }}>
        <Typography variant="h6">Bonjour {data.username}! 🥳</Typography>
        <Typography variant="body2" sx={{ letterSpacing: "0.25px" }}>
          Prêt à trouver des produits aujourd'hui?
        </Typography>
        {/* <Typography variant="h5" sx={{ my: 4, color: "primary.main" }}>
          $42.8k
        </Typography> */}
        {/* <Button size="small" variant="contained" href="/lien">
          VOIR LES URLS
        </Button> */}
        <Link
          to={`${pathGlobal.url.lien}`}
          className="flex items-center justify-center rounded-md border bg-violet px-2 py-1 mx-2 mt-6 font-medium text-white shadow-sm hover:bg-violet-900"
          style={{
            fontSize: 14,
            maxWidth: "140px",
            backgroundColor: "#9460ff",
          }}
        >
          VOIR LES URLS
        </Link>
        <TriangleImg alt="triangle background" src={`${imageSrc}`} />
        <TrophyImg alt="trophy" src={trophyimg} />
      </CardContent>
    </Card>
  );
};

function Dashboard({ data }: { data: listUrl }) {
  const billing = async () => {
    const session = await fetch("https://api.bot-vinted.com/billing", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stripeId: data?.stripeId,
      }),
    }).then((r) => r.text());
    // console.log(session);
    window.location.replace(session);
    // setSubscriptionData(session);
  };

  let current_plan: any;
  if (data.plan !== undefined) {
    Object.entries(user_plan).find(([key, value]) => {
      if (key === data.plan) {
        current_plan = value;
        return true;
      }
      return false;
    });
  }
  return (
    <ApexChartWrapper>
      <Grid container spacing={6}>
        <Grid item xs={12} md={5}>
          <Trophy data={data} />
        </Grid>
        <Grid item xs={12} md={7}>
          <StatisticsCard data={data} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={6} className="dashboard--cards">
            <Grid item xs={3}>
              <CardStatisticsVerticalComponent
                call={() => {
                  document.location.href = "/lien";
                }}
                stats={`${(
                  current_plan - data.urls.length
                ).toString()} Disponible(s)`}
                icon={<BriefcaseVariantOutline />}
                color="#9460ff"
                trendNumber=""
                title="Ajouter un lien"
                subtitle=""
              />
            </Grid>
            <Grid item xs={3}>
              <CardStatisticsVerticalComponent
                call={billing}
                stats="Résilier"
                title="Abonnement"
                trend="negative"
                color="#9460ff"
                trendNumber=""
                subtitle=""
                // icon={<CurrencyUsd />}
                icon={<Poll />}
              />
            </Grid>
            <Grid item xs={3}>
              <CardStatisticsVerticalComponent
                call={billing}
                stats="Upgrade"
                trend="negative"
                trendNumber=""
                title="Abonnement"
                subtitle=""
                icon={<Poll />}
              />
            </Grid>
            <Grid item xs={3}>
              <CardStatisticsVerticalComponent
                call={() => {}}
                stats="Fonctionnel"
                color="#9460ff"
                trend="negative"
                trendNumber=""
                subtitle=""
                title="État du Bot"
                icon={<HelpCircleOutline />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ApexChartWrapper>
  );
}

export default Dashboard;
