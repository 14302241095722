import React, { useEffect, useState } from "react";
import { utilisateurInscription } from "../../model/intefaceUtilisateur";
import { useFormik } from "formik";
import * as yup from "yup";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { MdOutlineDone } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { usePostSignupMutation } from "../../api/auth/auth.api";
import Swal from "sweetalert2";
import { Loading } from "../Loading";
import { useAppSelector } from "../../redux/customHooks";
import { store } from "../../redux/store";
import { userActions } from "../../redux/slices/userSlice";
import { pathGlobal } from "../../RouteName";

const InscriptionComponent = () => {
  const isAnimating = useAppSelector((state) => state.user.isAnimating);

  const navigate = useNavigate();

  let timer: number = 3000;
  const [etatLoading, setEtatLoading] = useState(false);

  const [postSignup, { isSuccess, isLoading, error, reset }] =
    usePostSignupMutation();
  // const [user, setUser] = useState<any>();
  const signupUser = async (userinfo: utilisateurInscription) => {
    return await postSignup(userinfo);
  };
  useEffect(() => {
    if (isLoading) {
      setEtatLoading(true);
    } else if (isSuccess) {
      setEtatLoading(false);
      navigate("/connexion");
      Swal.fire({
        text: "Compte activé avec sucèes veuillez vous connectez ",
        icon: "success",
        background: "#eaecf0",
        timer: timer,
        confirmButtonColor: "#9b51e0",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      reset();
    } else if (error) {
      setEtatLoading(false);
      if ("data" in error) {
        let response: any = error.data;
        Swal.fire({
          text: `${response.message}`,
          icon: "error",
          heightAuto: true,
          background: "#eaecf0",
          timer: timer,
          confirmButtonColor: "#9b51e0",
        });
      } else
        Swal.fire({
          text: "Opération échoué Erreur de connexion. Réessayez",
          icon: "error",
          heightAuto: true,
          background: "#eaecf0",
          timer: timer,
          confirmButtonColor: "#9b51e0",
        });

      reset();
    }
  }, [isLoading, isSuccess, error, reset, navigate]);
  const initialValues: utilisateurInscription = {
    username: "",
    email: "",
    password: "",
  };
  const [showPass, setShowPass] = useState(false);
  const [check, setCheck] = useState(false);
  const HandleSubmit = (values: utilisateurInscription) => {
    signupUser(values).then(() => {});
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      username: yup
        .string()
        .required("Champ  obligatoire")
        .min(4, "4 caractères minimum"),

      email: yup
        .string()
        .required("L'email est obligatoire")
        .email("L'email n'est pas valide"),

      password: yup
        .string()
        .required("Champ obligatoire")
        .min(8, "8 caractères minimum"),
    }),
    onSubmit: (values) => {
      HandleSubmit(values);
    },
  });
  return (
    <div className={"connexionContainer"}>
      <h1 id={"connex"}>Inscription</h1>
      <h5 id={"wel"}> Bienvenue sur BOT-VINTED </h5>
      <p className={"text1"}>
        L'aventure commence ici 🚀 Ne manquez plus vos articles préférés sur
        Vinted
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="pass-field-container">
          <div className="pass-container ">
            <input
              type={"text"}
              autoComplete={"given-name"}
              name="username"
              className="field"
              placeholder="Nom d'utilisateur"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              style={{
                borderColor: formik.errors.username && "red",
                backgroundColor: "white",
              }}
            />
          </div>
          {formik.touched.username && formik.errors.username ? (
            <div className="error-message">{formik.errors.username}</div>
          ) : (
            <div className="error-placeholder" />
          )}
        </div>

        <div className="pass-field-container">
          <div className="pass-container ">
            <input
              type={"text"}
              name="email"
              className="field"
              placeholder="E-mail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              style={{
                borderColor: formik.errors.email && "red",
                backgroundColor: "white",
              }}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className="error-message">{formik.errors.email}</div>
          ) : (
            <div className="error-placeholder" />
          )}
        </div>

        <div className="pass-field-container">
          <div className="pass-container ">
            <input
              type={showPass ? "text" : "password"}
              name="password"
              className="field"
              placeholder="Mot de passe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              style={{
                borderColor: formik.errors.password && "red",
                backgroundColor: "white",
              }}
            />
            <div
              className="pass-eye field"
              style={{ borderColor: formik.errors.password && "red" }}
              onClick={() => setShowPass(!showPass)}
            >
              {showPass ? (
                <BsFillEyeFill size={20} />
              ) : (
                <BsFillEyeSlashFill size={20} />
              )}
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="error-message">{formik.errors.password}</div>
          ) : (
            <div className="error-placeholder" />
          )}
        </div>
        <div className={"CaseCheckMdp"}>
          <button className={"rememberMdp"}>
            <div
              className={"checkedContainer"}
              style={{
                background: check ? "rgb(0,119,130)" : "",
                borderRadius: 0,
              }}
              onClick={(e) => {
                e.preventDefault();
                setCheck(!check);
              }}
            >
              {check && <MdOutlineDone />}
            </div>
            <div className={"terms"}>
              J'accepte la
              <Link
                to={"/"}
                style={{
                  color: "rgb(0,119,130)",
                }}
              >
                {" "}
                politique de confidentialité et les conditions
              </Link>
            </div>
          </button>
        </div>
        <button type="submit" name={"submit"}>
          <span>S'inscrire</span>
        </button>
      </form>
      <div className={"linkSignIn"}>
        Avez vous déja un compte?
        <Link
          to={`/${pathGlobal.auth.connexion}`}
          className={"inscription"}
          onClick={() => {
            store.dispatch(userActions.setAnimate(!isAnimating));
          }}
        >
          Connectez vous
        </Link>
      </div>
      {etatLoading && <Loading />}
    </div>
  );
};

export default InscriptionComponent;
