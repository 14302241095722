import React from "react";

const EmptyUrl = () => {
  return (
    <div className="EmptyUrl">
      <div className="svgList">
        <div
          title=""
          role="button"
          aria-label="animation"
          tabIndex={0}
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            margin: "0px auto",
            outline: "none",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 264 264"
            width="264"
            height="264"
            preserveAspectRatio="xMidYMid meet"
            style={{
              width: "100%",
              height: "100%",
              transform: "translate3d(0px, 0px, 0px)",
            }}
          >
            <defs>
              <clipPath id="__lottie_element_2">
                <rect width="264" height="264" x="0" y="0"></rect>
              </clipPath>
              <clipPath id="__lottie_element_13">
                <path d="M0,0 L264,0 L264,264 L0,264z"></path>
              </clipPath>
              <clipPath id="__lottie_element_14">
                <path
                  fill="#ffffff"
                  clipRule="nonzero"
                  d=" M132,24 C191.64700317382812,24 240,72.35299682617188 240,132 C240,191.64700317382812 191.64700317382812,240 132,240 C72.35299682617188,240 24,191.64700317382812 24,132 C24,72.35299682617188 72.35299682617188,24 132,24"
                  fillOpacity="1"
                ></path>
              </clipPath>
            </defs>
            <g clipPath="url(#__lottie_element_2)">
              <g
                transform="matrix(1,0,0,1,132,132)"
                opacity="1"
                style={{
                  display: "block",
                }}
              >
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path
                    fill="rgb(245,245,245)"
                    fillOpacity="1"
                    d=" M132,-132 C132,-132 132,132 132,132 C132,132 -132,132 -132,132 C-132,132 -132,-132 -132,-132 C-132,-132 132,-132 132,-132z"
                  ></path>
                </g>
              </g>
              <g
                transform="matrix(1,0,0,1,132,132)"
                opacity="1"
                style={{
                  display: "block",
                }}
              >
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path
                    fill="rgb(221,226,255)"
                    fillOpacity="1"
                    d=" M0,-108 C59.64699935913086,-108 108,-59.64699935913086 108,0 C108,59.64699935913086 59.64699935913086,108 0,108 C-59.64699935913086,108 -108,59.64699935913086 -108,0 C-108,-59.64699935913086 -59.64699935913086,-108 0,-108z"
                  ></path>
                </g>
              </g>
              <g
                clipPath="url(#__lottie_element_13)"
                transform="matrix(1,0,0,1,0,0)"
                opacity="1"
                style={{
                  display: "block",
                }}
              >
                <g clipPath="url(#__lottie_element_14)">
                  <g
                    transform="matrix(1,0,0,1,132,178.0800018310547)"
                    opacity="1"
                    style={{
                      display: "block",
                    }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            fill="rgb(255,255,255)"
                            fillOpacity="1"
                            d=" M-66.49299621582031,-12.550000190734863 C-66.49299621582031,-12.550000190734863 68.52200317382812,-12.550000190734863 68.52200317382812,-12.550000190734863 C70.927001953125,-12.550000190734863 72.87699890136719,-10.600000381469727 72.87699890136719,-8.194999694824219 C72.87699890136719,-8.194999694824219 72.87699890136719,22.29199981689453 72.87699890136719,22.29199981689453 C72.87699890136719,24.69700050354004 70.927001953125,26.64699935913086 68.52200317382812,26.64699935913086 C68.52200317382812,26.64699935913086 -66.49299621582031,26.64699935913086 -66.49299621582031,26.64699935913086 C-68.89800262451172,26.64699935913086 -70.8479995727539,24.69700050354004 -70.8479995727539,22.29199981689453 C-70.8479995727539,22.29199981689453 -70.8479995727539,-8.194999694824219 -70.8479995727539,-8.194999694824219 C-70.8479995727539,-10.600000381469727 -68.89800262451172,-12.550000190734863 -66.49299621582031,-12.550000190734863z"
                          ></path>
                        </g>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            fill="rgb(46,120,243)"
                            fillOpacity="1"
                            d=" M-58.255001068115234,-6.01800012588501 C-58.255001068115234,-6.01800012588501 -44.24399948120117,-6.01800012588501 -44.24399948120117,-6.01800012588501 C-40.89699935913086,-6.01800012588501 -38.18299865722656,-3.303999900817871 -38.18299865722656,0.0430000014603138 C-38.18299865722656,0.0430000014603138 -38.18299865722656,14.053000450134277 -38.18299865722656,14.053000450134277 C-38.18299865722656,17.399999618530273 -40.89699935913086,20.11400032043457 -44.24399948120117,20.11400032043457 C-44.24399948120117,20.11400032043457 -58.255001068115234,20.11400032043457 -58.255001068115234,20.11400032043457 C-61.60200119018555,20.11400032043457 -64.31500244140625,17.399999618530273 -64.31500244140625,14.053000450134277 C-64.31500244140625,14.053000450134277 -64.31500244140625,0.0430000014603138 -64.31500244140625,0.0430000014603138 C-64.31500244140625,-3.303999900817871 -61.60200119018555,-6.01800012588501 -58.255001068115234,-6.01800012588501z"
                          ></path>
                        </g>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          fill="rgb(217,217,217)"
                          fillOpacity="1"
                          d=" M60.900001525878906,-0.5740000009536743 C60.900001525878906,-0.5740000009536743 60.900001525878906,-0.5740000009536743 60.900001525878906,-0.5740000009536743 C63.90700149536133,-0.5740000009536743 66.34400177001953,1.8630000352859497 66.34400177001953,4.869999885559082 C66.34400177001953,4.869999885559082 66.34400177001953,4.869999885559082 66.34400177001953,4.869999885559082 C66.34400177001953,7.876999855041504 63.90700149536133,10.314000129699707 60.900001525878906,10.314000129699707 C60.900001525878906,10.314000129699707 60.900001525878906,10.314000129699707 60.900001525878906,10.314000129699707 C57.893001556396484,10.314000129699707 55.45600128173828,7.876999855041504 55.45600128173828,4.869999885559082 C55.45600128173828,4.869999885559082 55.45600128173828,4.869999885559082 55.45600128173828,4.869999885559082 C55.45600128173828,1.8630000352859497 57.893001556396484,-0.5740000009536743 60.900001525878906,-0.5740000009536743z"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-29.357999801635742,1.6039999723434448 C-29.357999801635742,1.6039999723434448 38.14899826049805,1.6039999723434448 38.14899826049805,1.6039999723434448"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-29.357999801635742,12.491999626159668 C-29.357999801635742,12.491999626159668 -17.125,12.491999626159668 -17.125,12.491999626159668"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-5.7779998779296875,12.491999626159668 C-5.7779998779296875,12.491999626159668 38.14899826049805,12.491999626159668 38.14899826049805,12.491999626159668"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    transform="matrix(1,0,0,1,132,132.00100708007812)"
                    opacity="1"
                    style={{
                      display: "block",
                    }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            fill="rgb(255,255,255)"
                            fillOpacity="1"
                            d=" M-66.49299621582031,-12.550000190734863 C-66.49299621582031,-12.550000190734863 68.52200317382812,-12.550000190734863 68.52200317382812,-12.550000190734863 C70.927001953125,-12.550000190734863 72.87699890136719,-10.600000381469727 72.87699890136719,-8.194999694824219 C72.87699890136719,-8.194999694824219 72.87699890136719,22.29199981689453 72.87699890136719,22.29199981689453 C72.87699890136719,24.69700050354004 70.927001953125,26.64699935913086 68.52200317382812,26.64699935913086 C68.52200317382812,26.64699935913086 -66.49299621582031,26.64699935913086 -66.49299621582031,26.64699935913086 C-68.89800262451172,26.64699935913086 -70.8479995727539,24.69700050354004 -70.8479995727539,22.29199981689453 C-70.8479995727539,22.29199981689453 -70.8479995727539,-8.194999694824219 -70.8479995727539,-8.194999694824219 C-70.8479995727539,-10.600000381469727 -68.89800262451172,-12.550000190734863 -66.49299621582031,-12.550000190734863z"
                          ></path>
                        </g>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            fill="rgb(46,120,243)"
                            fillOpacity="1"
                            d=" M-58.255001068115234,-6.01800012588501 C-58.255001068115234,-6.01800012588501 -44.24399948120117,-6.01800012588501 -44.24399948120117,-6.01800012588501 C-40.89699935913086,-6.01800012588501 -38.18299865722656,-3.303999900817871 -38.18299865722656,0.0430000014603138 C-38.18299865722656,0.0430000014603138 -38.18299865722656,14.053000450134277 -38.18299865722656,14.053000450134277 C-38.18299865722656,17.399999618530273 -40.89699935913086,20.11400032043457 -44.24399948120117,20.11400032043457 C-44.24399948120117,20.11400032043457 -58.255001068115234,20.11400032043457 -58.255001068115234,20.11400032043457 C-61.60200119018555,20.11400032043457 -64.31500244140625,17.399999618530273 -64.31500244140625,14.053000450134277 C-64.31500244140625,14.053000450134277 -64.31500244140625,0.0430000014603138 -64.31500244140625,0.0430000014603138 C-64.31500244140625,-3.303999900817871 -61.60200119018555,-6.01800012588501 -58.255001068115234,-6.01800012588501z"
                          ></path>
                        </g>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          fill="rgb(217,217,217)"
                          fillOpacity="1"
                          d=" M60.900001525878906,-0.5740000009536743 C60.900001525878906,-0.5740000009536743 60.900001525878906,-0.5740000009536743 60.900001525878906,-0.5740000009536743 C63.90700149536133,-0.5740000009536743 66.34400177001953,1.8630000352859497 66.34400177001953,4.869999885559082 C66.34400177001953,4.869999885559082 66.34400177001953,4.869999885559082 66.34400177001953,4.869999885559082 C66.34400177001953,7.876999855041504 63.90700149536133,10.314000129699707 60.900001525878906,10.314000129699707 C60.900001525878906,10.314000129699707 60.900001525878906,10.314000129699707 60.900001525878906,10.314000129699707 C57.893001556396484,10.314000129699707 55.45600128173828,7.876999855041504 55.45600128173828,4.869999885559082 C55.45600128173828,4.869999885559082 55.45600128173828,4.869999885559082 55.45600128173828,4.869999885559082 C55.45600128173828,1.8630000352859497 57.893001556396484,-0.5740000009536743 60.900001525878906,-0.5740000009536743z"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-29.357999801635742,1.6039999723434448 C-29.357999801635742,1.6039999723434448 38.14899826049805,1.6039999723434448 38.14899826049805,1.6039999723434448"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-29.357999801635742,12.491999626159668 C-29.357999801635742,12.491999626159668 -17.125,12.491999626159668 -17.125,12.491999626159668"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-5.7779998779296875,12.491999626159668 C-5.7779998779296875,12.491999626159668 38.14899826049805,12.491999626159668 38.14899826049805,12.491999626159668"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    transform="matrix(0.9944443106651306,-0.10526374727487564,0.10526374727487564,0.9944443106651306,128.80142211914062,62.01908874511719)"
                    opacity="1"
                    style={{
                      display: "block",
                    }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            fill="rgb(255,255,255)"
                            fillOpacity="1"
                            d=" M-66.49299621582031,-12.550000190734863 C-66.49299621582031,-12.550000190734863 68.52200317382812,-12.550000190734863 68.52200317382812,-12.550000190734863 C70.927001953125,-12.550000190734863 72.87699890136719,-10.600000381469727 72.87699890136719,-8.194999694824219 C72.87699890136719,-8.194999694824219 72.87699890136719,22.29199981689453 72.87699890136719,22.29199981689453 C72.87699890136719,24.69700050354004 70.927001953125,26.64699935913086 68.52200317382812,26.64699935913086 C68.52200317382812,26.64699935913086 -66.49299621582031,26.64699935913086 -66.49299621582031,26.64699935913086 C-68.89800262451172,26.64699935913086 -70.8479995727539,24.69700050354004 -70.8479995727539,22.29199981689453 C-70.8479995727539,22.29199981689453 -70.8479995727539,-8.194999694824219 -70.8479995727539,-8.194999694824219 C-70.8479995727539,-10.600000381469727 -68.89800262451172,-12.550000190734863 -66.49299621582031,-12.550000190734863z"
                          ></path>
                        </g>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                          <path
                            fill="rgb(58,184,160)"
                            fillOpacity="1"
                            d=" M-58.255001068115234,-6.01800012588501 C-58.255001068115234,-6.01800012588501 -44.24399948120117,-6.01800012588501 -44.24399948120117,-6.01800012588501 C-40.89699935913086,-6.01800012588501 -38.18299865722656,-3.303999900817871 -38.18299865722656,0.0430000014603138 C-38.18299865722656,0.0430000014603138 -38.18299865722656,14.053000450134277 -38.18299865722656,14.053000450134277 C-38.18299865722656,17.399999618530273 -40.89699935913086,20.11400032043457 -44.24399948120117,20.11400032043457 C-44.24399948120117,20.11400032043457 -58.255001068115234,20.11400032043457 -58.255001068115234,20.11400032043457 C-61.60200119018555,20.11400032043457 -64.31500244140625,17.399999618530273 -64.31500244140625,14.053000450134277 C-64.31500244140625,14.053000450134277 -64.31500244140625,0.0430000014603138 -64.31500244140625,0.0430000014603138 C-64.31500244140625,-3.303999900817871 -61.60200119018555,-6.01800012588501 -58.255001068115234,-6.01800012588501z"
                          ></path>
                        </g>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          fill="rgb(217,217,217)"
                          fillOpacity="1"
                          d=" M60.900001525878906,-0.5740000009536743 C60.900001525878906,-0.5740000009536743 60.900001525878906,-0.5740000009536743 60.900001525878906,-0.5740000009536743 C63.90700149536133,-0.5740000009536743 66.34400177001953,1.8630000352859497 66.34400177001953,4.869999885559082 C66.34400177001953,4.869999885559082 66.34400177001953,4.869999885559082 66.34400177001953,4.869999885559082 C66.34400177001953,7.876999855041504 63.90700149536133,10.314000129699707 60.900001525878906,10.314000129699707 C60.900001525878906,10.314000129699707 60.900001525878906,10.314000129699707 60.900001525878906,10.314000129699707 C57.893001556396484,10.314000129699707 55.45600128173828,7.876999855041504 55.45600128173828,4.869999885559082 C55.45600128173828,4.869999885559082 55.45600128173828,4.869999885559082 55.45600128173828,4.869999885559082 C55.45600128173828,1.8630000352859497 57.893001556396484,-0.5740000009536743 60.900001525878906,-0.5740000009536743z"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-29.357999801635742,1.6039999723434448 C-29.357999801635742,1.6039999723434448 -5.513000011444092,1.6039999723434448 13.833000183105469,1.6039999723434448"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-29.357999801635742,12.491999626159668 C-29.357999801635742,12.491999626159668 -21.0049991607666,12.491999626159668 -18.104000091552734,12.491999626159668"
                        ></path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fillOpacity="0"
                          stroke="rgb(217,217,217)"
                          strokeOpacity="1"
                          strokeWidth="5"
                          d=" M-5.7779998779296875,12.491999626159668 C-5.7779998779296875,12.491999626159668 9.73799991607666,12.491999626159668 22.32699966430664,12.491999626159668"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g
                transform="matrix(1,0,0,1,132,132)"
                opacity="1"
                style={{
                  display: "block",
                }}
              >
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <path
                        fill="rgb(60,193,150)"
                        fillOpacity="1"
                        d=" M75.375,22 C75.375,22 75.375,22 75.375,22 C93.0479965209961,22 107.375,36.32699966430664 107.375,54 C107.375,54 107.375,54 107.375,54 C107.375,71.6729965209961 93.0479965209961,86 75.375,86 C75.375,86 75.375,86 75.375,86 C57.70199966430664,86 43.375,71.6729965209961 43.375,54 C43.375,54 43.375,54 43.375,54 C43.375,36.32699966430664 57.70199966430664,22 75.375,22z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g
                transform="matrix(1,0,0,1,132,132)"
                opacity="1"
                style={{
                  display: "block",
                }}
              >
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fillOpacity="0"
                      stroke="rgb(245,245,245)"
                      strokeOpacity="1"
                      strokeWidth="3"
                      d=" M75.375,53.12099838256836 C75.375,53.12099838256836 70.14600372314453,44.77299880981445 64.5530014038086,44.77299880981445 C58.959999084472656,44.77299880981445 55.12200164794922,48.51100158691406 55.12200164794922,53.12099838256836 C55.12200164794922,57.73099899291992 58.959999084472656,61.46799850463867 64.5530014038086,61.46799850463867 C70.14600372314453,61.46799850463867 75.375,53.12099838256836 75.375,53.12099838256836z"
                    ></path>
                  </g>
                </g>
              </g>
              <g
                transform="matrix(1,0,0,1,132,132)"
                opacity="1"
                style={{
                  display: "block",
                }}
              >
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fillOpacity="0"
                      stroke="rgb(245,245,245)"
                      strokeOpacity="1"
                      strokeWidth="3"
                      d=" M75.375,53.12099838256836 C75.375,53.12099838256836 80.60399627685547,61.46799850463867 86.1969985961914,61.46799850463867 C91.79000091552734,61.46799850463867 95.62799835205078,57.73099899291992 95.62799835205078,53.12099838256836 C95.62799835205078,48.51100158691406 91.79000091552734,44.77299880981445 86.1969985961914,44.77299880981445 C80.60399627685547,44.77299880981445 75.375,53.12099838256836 75.375,53.12099838256836z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div>Pas encore de Liens</div>
      <div
        style={{
          color: "rgba(0,0,0,.5)",
        }}
      >
        Lorsque vous ajoutez des liens, vous les verrez ici
      </div>
    </div>
  );
};

export default EmptyUrl;
