import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import { userActions } from "../redux/slices/userSlice";
import { store } from "../redux/store";

export const ResponseFetch = (
  baseUrl: string
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("tokenBotVinted") as string;
      // By default, if we have a token in the store, let's use that for authenticated requests
      if (token) headers.set("x-access-token", token);

      return headers;
    },
  });
  const baseQueryWithLogout: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      localStorage.removeItem("tokenBotVinted");
      // sessionStorage.removeItem("userIdentifiantConnexion");
      //sessionStorage.removeItem("colorContainer");
      //sessionStorage.setItem("isdeconnectAlert", "true");
      localStorage.removeItem("userConnexion");
      store.dispatch(userActions.updateAuthenticated(false));
      //  window.location.href = '/';
    }
    return result;
  };
  return baseQueryWithLogout;
};
