import React, { useEffect } from "react";
import { useAppSelector } from "../../redux/customHooks";
import { post } from "../../model/intefaceUtilisateur";
import {
  useGetAlertPostsQuery,
  useGetUserQuery,
} from "../../api/user/user.api";
import { Loading } from "../Loading";
import ErrorPage from "../../pages/ErrorPage";
import ReactTimeAgo from "react-time-ago";
import { useLocation } from "react-router-dom";
import { pathGlobal } from "../../RouteName";

const AlerteComposant = () => {
  const urlClick = useAppSelector((state) => state.user.urlClick);
  const location = useLocation();
  const alerteUrl =
    location.pathname.replace("/", "") ===
    pathGlobal.url.lien + "/" + pathGlobal.alerte.alerte;
  const tousAlerteUrl =
    location.pathname.replace("/", "") === pathGlobal.alerte.alerteTous;

  const { data, error, isLoading, refetch } = useGetAlertPostsQuery(
    encodeURIComponent(urlClick)
  );

  const {
    data: dataListUrl,
    error: errorListUrl,
    isLoading: isLoadingListUrl,
    refetch: refetchListUrl,
  } = useGetUserQuery();

  const [alertList, setAlertList] = React.useState<post[]>([]);
  useEffect(
    () => {
      let listPost: post[] = [];
      if (data && alerteUrl) {
        listPost = [...data.posts];
      } else if (dataListUrl && tousAlerteUrl) {
        listPost = [...dataListUrl.notifications].reverse();
      }

      listPost.splice(20);
      if (alertList.length !== 0) {
        let alertListCopy: post[] = [...alertList];
        alertListCopy = alertListCopy.filter(
          (entry1) =>
            !listPost.some((entry2) => entry1.post_id === entry2.post_id)
        );

        alertListCopy = [...listPost, ...alertListCopy];
        if (alertListCopy.length > 200) {
          alertListCopy.splice(200);
        }
        setAlertList(alertListCopy);
      } else {
        setAlertList(listPost);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, dataListUrl]
  );

  useEffect(() => {
    const Time = () =>
      setTimeout(() => {
        if (alerteUrl) refetch();
        else if (tousAlerteUrl) refetchListUrl();
      }, 5000);

    Time();
    return () => {
      clearTimeout(Time());
    };
  });

  function display() {
    if (data) {
      if (data.posts.length !== 0 || alertList.length !== 0) {
        return (
          <>
            {alertList.map((post, key) => {
              let totalavis =
                post.post_user_negatif +
                post.post_user_neutre +
                post.post_user_positive;
              let note =
                (post.post_user_positive * 5 +
                  post.post_user_neutre * 2.5 +
                  post.post_user_negatif * 1) /
                totalavis;
              return (
                <div key={key} className={"displayalertContainer"}>
                  <img src={post.post_photo} alt={`phot${key}`} />
                  <div className={"infoAlertContainer"}>
                    <div className={"infoalertContent"}>
                      <div style={{ fontSize: 15 }}>
                        {post.post_title}
                        {"\n"}
                      </div>
                    </div>
                    <div style={{ fontSize: 12 }}>
                      {" | "}
                      {post.post_brand}
                      {" / "}
                      {post.post_size}
                      {" | "}
                    </div>
                    <div className={"text"} style={{ fontSize: 10 }}>
                      {post.post_description}
                    </div>
                    <div style={{ fontSize: 16 }}>
                      {post.post_price}
                      {" €"}
                    </div>
                    <div style={{ fontSize: 10 }}>
                      {totalavis}
                      {"("}
                      {note.toFixed(2)}
                      {"🌟)"}
                    </div>
                    {post.post_promoted === true ? (
                      <div
                        style={{
                          fontSize: 7,
                          backgroundColor: "red",
                          color: "white",
                        }}
                      >
                        Boosté
                      </div>
                    ) : (
                      <></>
                    )}

                    <div style={{ fontSize: 10 }}>
                      <ReactTimeAgo
                        date={new Date(post.post_time)}
                        locale="fr"
                      />
                    </div>
                    <button
                      onClick={function () {
                        window.open(post.post_url, "_blank");
                      }}
                    >
                      Voir le produit
                    </button>
                    <button
                      onClick={function () {
                        window.open(post.post_url, "_blank");
                      }}
                      name={"annonceur"}
                    >
                      Acheter
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        );
      } else
        return (
          <div style={{ textAlign: "center" }}>
            Le bot est en pleine recherche 🚀 | Généralement vos premiers
            articles vont apparaitre ici en moins de 3 minutes | Si après 10
            minutes vous n'avez toujours rien, votre filtre de recherche est
            surement trop stricte.
          </div>
        );
    } else if (dataListUrl) {
      if (dataListUrl.notifications.length !== 0 || alertList.length !== 0) {
        return (
          <>
            {alertList.map((post, key) => {
              let totalavis =
                post.post_user_negatif +
                post.post_user_neutre +
                post.post_user_positive;
              let note =
                (post.post_user_positive * 5 +
                  post.post_user_neutre * 2.5 +
                  post.post_user_negatif * 1) /
                totalavis;
              return (
                <div key={key} className={"displayalertContainer"}>
                  <img src={post.post_photo} alt={`phot${key}`} />
                  <div className={"infoAlertContainer"}>
                    <div className={"infoalertContent"}>
                      <div style={{ fontSize: 15 }}>
                        {post.post_title}
                        {"\n"}
                      </div>
                    </div>
                    <div style={{ fontSize: 12 }}>
                      {" | "}
                      {post.post_brand}
                      {" / "}
                      {post.post_size}
                      {" | "}
                    </div>
                    <div className={"text"} style={{ fontSize: 10 }}>
                      {post.post_description}
                    </div>
                    <div style={{ fontSize: 16 }}>
                      {post.post_price}
                      {" €"}
                    </div>
                    <div style={{ fontSize: 10 }}>
                      {totalavis}
                      {"("}
                      {note.toFixed(2)}
                      {"🌟)"}
                    </div>
                    {post.post_promoted === true ? (
                      <div
                        style={{
                          fontSize: 7,
                          backgroundColor: "red",
                          color: "white",
                        }}
                      >
                        Boosté
                      </div>
                    ) : (
                      <></>
                    )}

                    <div style={{ fontSize: 10 }}>
                      <ReactTimeAgo
                        date={new Date(post.post_time)}
                        locale="fr"
                      />
                    </div>
                    <button
                      onClick={function () {
                        window.open(post.post_url, "_blank");
                      }}
                    >
                      Voir le produit
                    </button>
                    <button
                      onClick={function () {
                        window.open(post.post_url, "_blank");
                      }}
                      name={"annonceur"}
                    >
                      Acheter
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        );
      } else
        return (
          <div style={{ textAlign: "center" }}>
            Le bot est en pleine recherche 🚀 | Généralement vos premiers
            articles vont apparaitre ici en moins de 3 minutes | Si après 10
            minutes vous n'avez toujours rien, votre filtre de recherche est
            surement trop stricte.
          </div>
        );
    }
  }

  const displayAlerte = (): JSX.Element => {
    if (alerteUrl) {
      return data ? (
        <div className={"alerteComposantContent"}>{display()}</div>
      ) : isLoading ? (
        <Loading />
      ) : error ? (
        <ErrorPage message={"...Oups, Il y'a une erreur😥"} refresh={refetch} />
      ) : (
        <div style={{ textAlign: "center" }}>
          Le bot est en pleine recherche 🚀 | Généralement vos premiers articles
          vont apparaitre ici en moins de 3 minutes | Si après 10 minutes vous
          n'avez toujours rien, votre filtre de recherche est surement trop
          stricte.
        </div>
      );
    } else if (tousAlerteUrl) {
      return dataListUrl ? (
        <div className={"alerteComposantContent"}>{display()}</div>
      ) : isLoadingListUrl ? (
        <Loading />
      ) : errorListUrl ? (
        <ErrorPage
          message={"...Oups, Il y'a une erreur😥"}
          refresh={refetchListUrl}
        />
      ) : (
        <div style={{ textAlign: "center" }}>
          Le bot est en pleine recherche 🚀 | Généralement vos premiers articles
          vont apparaitre ici en moins de 3 minutes | Si après 10 minutes vous
          n'avez toujours rien, votre filtre de recherche est surement trop
          stricte.
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          Le bot est en pleine recherche 🚀 | Généralement vos premiers articles
          vont apparaitre ici en moins de 3 minutes | Si après 10 minutes vous
          n'avez toujours rien, votre filtre de recherche est surement trop
          stricte.
        </div>
      );
    }
  };
  return <div className={"alerteComposantContainer"}>{displayAlerte()}</div>;
};

export default AlerteComposant;
