import React from 'react';
import FaqComponent from "../../composants/faq/FaqComponent";

const Faq = () => {
    return (
        <div>
            <FaqComponent/>
        </div>
    );
};

export default Faq;