import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userReducer } from "./slices/userSlice";
import { userApi } from "../api/user/user.api";
import { authApi } from "../api/auth/auth.api";
import { urlReducer } from "./slices/urlSlice";

const persistConfig = {
  key: "frontend",
  version: 1,
  storage,
};
const persistedReducerUser = persistReducer(persistConfig, userReducer);
const persistedReducerUrl = persistReducer(persistConfig, urlReducer);

export const store = configureStore({
  reducer: {
    user: persistedReducerUser,
    url: persistedReducerUrl,

    //APi
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      //     {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat(
      // logger,
      authApi.middleware,
      userApi.middleware
    ),
});

export let persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
