import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { utilisateurConnexion } from "../../model/intefaceUtilisateur";
import * as yup from "yup";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineDone } from "react-icons/md";
import { usePostSigninMutation } from "../../api/auth/auth.api";
import Swal from "sweetalert2";
import { Loading } from "../Loading";
import { userActions } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { pathGlobal } from "../../RouteName";
import { store } from "../../redux/store";
import { useAppSelector } from "../../redux/customHooks";

const ConnexionComposant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let timer: number = 3000;
  const [etatLoading, setEtatLoading] = useState(false);
  const [postSignin, { isSuccess, isLoading, error, reset }] =
    usePostSigninMutation();

  const loginUser = async (userinfo: utilisateurConnexion) => {
    return await postSignin(userinfo);
  };

  useEffect(() => {
    if (isLoading) {
      setEtatLoading(true);
    } else if (isSuccess) {
      setEtatLoading(false);
      dispatch(userActions.updateAuthenticated(true));
      navigate(`/${pathGlobal.url.dashboard}`);
      reset();
    } else if (error) {
      setEtatLoading(false);
      if ("data" in error) {
        let response: any = error.data;
        Swal.fire({
          text: `${response.message}`,
          icon: "error",
          heightAuto: true,
          background: "#eaecf0",
          timer: timer,
          confirmButtonColor: "#9b51e0",
        });
      } else
        Swal.fire({
          text: "Opération échoué Erreur de connexion. Réessayez",
          icon: "error",
          heightAuto: true,
          background: "#eaecf0",
          timer: timer,
          confirmButtonColor: "#9b51e0",
        });

      reset();
    }
  }, [isLoading, isSuccess, error, reset, dispatch, navigate, timer]);
  const initialValues: utilisateurConnexion = {
    email: "",
    password: "",
  };
  const [showPass, setShowPass] = useState(false);
  const [check, setCheck] = useState(false);
  const HandleSubmit = (values: utilisateurConnexion) => {
    loginUser(values).then(() => {});
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      email: yup
        .string()
        .required("L'email est obligatoire")
        .email("L'email n'est pas valide"),
      // .test("email_or_phone", "L'email / Phone est invalide", (value) => {
      //   return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
      // })
      password: yup
        .string()
        .required("Champ obligatoire")
        .min(4, "4 caractères minimum"),
    }),
    onSubmit: (values) => {
      HandleSubmit(values);
    },
  });
  const isAnimating = useAppSelector((state) => state.user.isAnimating);
  return (
    <div className={"connexionContainer"}>
      <h1 id={"connex"}>Connexion</h1>
      <h5
        id={"wel"}
        style={{
          margin: "8% 0 7% 0",
        }}
      >
        {" "}
        Bienvenue sur BOT-VINTED
      </h5>
      <p className={"text1"}>
        {" "}
        Veuillez vous connecter à votre compte et commencer l’aventure !
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="pass-field-container">
          <div className="pass-container ">
            <input
              type={"text"}
              name="email"
              className="field"
              placeholder="E-mail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              style={{
                borderColor: formik.errors.email && "red",
                backgroundColor: "white",
              }}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className="error-message">{formik.errors.email}</div>
          ) : (
            <div className="error-placeholder" />
          )}
        </div>

        <div className="pass-field-container">
          <div className="pass-container ">
            <input
              type={showPass ? "text" : "password"}
              name="password"
              className="field"
              placeholder="Mot de passe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              style={{
                borderColor: formik.errors.password && "red",
                backgroundColor: "white",
              }}
            />
            <div
              className="pass-eye field"
              style={{ borderColor: formik.errors.password && "red" }}
              onClick={() => setShowPass(!showPass)}
            >
              {showPass ? (
                <BsFillEyeFill size={20} />
              ) : (
                <BsFillEyeSlashFill size={20} />
              )}
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="error-message">{formik.errors.password}</div>
          ) : (
            <div className="error-placeholder" />
          )}
        </div>
        <div className={"CaseCheckMdp"}>
          <button
            className={"rememberMdp"}
            onClick={(e) => {
              e.preventDefault();
              setCheck(!check);
            }}
          >
            <div
              className={"checkedContainer"}
              style={
                check ? { background: "rgb(0,119,130)" } : { background: "" }
              }
            >
              {check && <MdOutlineDone />}
            </div>
            <div className={"checkName"}>Se Souvenir</div>
          </button>
          <Link to={"/"} className={"mdpForgot"}>
            Mot de passe oublié?
          </Link>
        </div>
        <button type="submit" name={"submit"}>
          <span>Se connecter</span>
        </button>
      </form>
      <div
        className={"linkSignIn"}
        style={{
          fontSize: 14,
        }}
        onClick={() => {
          store.dispatch(userActions.setAnimate(!isAnimating));
        }}
      >
        Nouveau ?
        <Link to={`/${pathGlobal.auth.inscription}`} className={"inscription"}>
          Inscrivez vous
        </Link>
      </div>
      {etatLoading && <Loading />}
    </div>
  );
};

export default ConnexionComposant;
