import React from "react";
import {
    CircleLoader,
    ClipLoader,
    ClockLoader,
    DotLoader,
    HashLoader,
    MoonLoader,
    PuffLoader,
    RingLoader,
} from "react-spinners";

/**Pour le chargement*/
export const Loading = () => {
  const spinner = (): JSX.Element => {
    let rand = Math.floor(Math.random() * 9),
      color: string = "#9b51e0";

    return rand === 0 ? (
      <ClipLoader size={35} color={color} />
    ) : rand === 1 ? (
      <CircleLoader size={35} color={color} />
    ) : rand === 2 ? (
      <ClockLoader size={35} color={color} />
    ) : rand === 3 ? (
      <DotLoader size={35} color={color} />
    ) : rand === 4 ? (
      <span
        className={`fa fa-spinner fa-pulse
                      fa-3x fa-fw`}
        style={{ color: color }}
      ></span>
    ) : rand === 5 ? (
      <HashLoader size={35} color={color} />
    ) : rand === 6 ? (
      <MoonLoader size={35} color={color} />
    ) : rand === 7 ? (
      <PuffLoader size={35} color={color} />
    ) : rand === 8 ? (
      <RingLoader size={35} color={color} />
    ) : (
      <></>
    );
  };
  return (
    <div className={"AddLoading"}>
      <div className="Loading">
        {spinner()}

        {/*<b>Chargement . . .</b>*/}

        <div className="loader">
          <span className="lettre">C</span>
          <span className="lettre">h</span>
          <span className="lettre">a</span>
          <span className="lettre">r</span>
          <span className="lettre">g</span>
          <span className="lettre">e</span>
          <span className="lettre">m</span>
          <span className="lettre">e</span>
          <span className="lettre">n</span>
          <span className="lettre">t</span>
          <span className="lettre">.</span>
          <span className="lettre">.</span>
          <span className="lettre">.</span>
        </div>
      </div>
    </div>
  );
};
