import React from "react";
import CategoriesList from "./CategoriesList";
import AccordionWrapper from "../AccordionWrapper";
import { Accordion } from "react-accessible-accordion";
// import logo from "../../medias/WELINA.png";
// import insta from "../../medias/sidebar/social/instagram.png";
// import facebook from "../../medias/sidebar/social/facebook.png";
// import snapchat from "../../medias/sidebar/social/snapchat.png";
import { CATEGORIES } from "./categories";

const SideBar = () =>
  // props: {
  // showsidebaronclick: () => void;
  // }
  {
    const categories = CATEGORIES;

    return (
      // <div id="sidemenu-container">
      <div
        id="sidemenu-content"
        // onMouseLeave={props.showsidebaronclick}
      >
        {/*<div id="sidemenu-Img">*/}
        {/*    <Link to={`/`}>*/}
        {/*        <img alt="Welcome_Logo" src={logo} onClick={props.showsidebaronclick}/>*/}
        {/*    </Link>*/}
        {/*</div>*/}
        <AccordionWrapper>
          <div id="container-wrapper">
            <Accordion allowMultipleExpanded={false} allowZeroExpanded={true}>
              {categories.map((cat) => (
                <CategoriesList
                  key={cat.key}
                  index={cat.key}
                  categorie={cat.categorie}
                  icon={cat.icon}
                  subcategories={cat.subcategories}
                  route={cat.route}
                  // onclick={props.showsidebaronclick}
                />
              ))}
            </Accordion>
          </div>
        </AccordionWrapper>
        {/*<div className="sidemenu-footer">*/}
        {/*    <div className="sidemenu-us">*/}
        {/*        /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
        {/*        <a className="sidemenu-us-link" href="#">*/}
        {/*            Aide*/}
        {/*        </a>*/}
        {/*        /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
        {/*        <a className="sidemenu-us-link" href="#">*/}
        {/*            Qui sommes-nous?*/}
        {/*        </a>*/}
        {/*        /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
        {/*        <div className="sidemenu-us-linkReseau">*/}
        {/*            Nos réseaux :*/}
        {/*            <a href="https://www.facebook.com">*/}
        {/*                <img src={facebook} alt={'facebookSocial'}/>*/}
        {/*            </a>*/}
        {/*            <a href="https://www.snapchat.com">*/}
        {/*                <img src={snapchat} alt={'snapchatSocial'}/>*/}
        {/*            </a>*/}
        {/*            <a href="https://www.instagram.com">*/}
        {/*                <img src={insta} alt={'InstaSocial'}/>*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
      //     <div
      //         className="sidemenu-close"
      //         /*Ici ayant recu l'acessoire de son composant parent Headercomposant
      //          * l'etat se mettra directement a jour lorsqu'on click sur la sidebar
      //          * elle passera de true a false ce qui entraine la fermeture de la sidebar
      //          * car a false aucun acessoire n'est transmis*/
      //        // onClick={props.showsidebaronclick}
      //
      //     >
      //         <AiOutlineClose className="AiOutlineClose"/>
      //     </div>
      //
      // </div>
    );
  };

export default SideBar;
