import React from 'react';
import ConnexionComposant from "../../composants/auth/ConnexionComposant";
import tree from "../../images/connexion/tree-2.png";
import tree1 from "../../images/connexion/tree-3.png";

const Connexion = () => {
    return (
        <div className={"connexionpageContainer"}>
            <img src={tree} alt={"tree"} className={"connexionImage1"}/>
            <ConnexionComposant/>
            <img src={tree1} alt={"tree1"} className={"connexionImage2"}/>
        </div>
    );
};

export default Connexion;