import React, { useEffect } from "react";
import "./App.scss";
import "../src/styles/styles.scss";
import { persistor, store } from "./redux/store";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import LienComposant from "./composants/lien/LienComposant";
import Connexion from "./pages/auth/Connexion";
import Inscription from "./pages/auth/Inscription";
import { useAppSelector } from "./redux/customHooks";
import { userActions } from "./redux/slices/userSlice";
import { pathGlobal } from "./RouteName";
import { CSSTransition } from "react-transition-group";
import Alerte from "./pages/alerteLien/Alerte";
import Dashboard from "./pages/dashboard/Dashboard";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useGetUserQuery } from "./api/user/user.api";
import Faq from "./pages/faq/Faq";

export let userConnexion: {
  username: string;
  email: string;
  plan: string;
} = JSON.parse(
  localStorage.getItem("userConnexion") ||
    JSON.stringify({
      username: "",
      email: "",
      plan: "",
    })
);

export const backgroundTheme = "#eaecf0";
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <div
            className="App"
            style={{
              background: backgroundTheme,
            }}
          >
            <Main />
          </div>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

function Main(): JSX.Element {
  const { data, error, isLoading, refetch } = useGetUserQuery();
  useEffect(() => {
    if (data !== undefined) {
      localStorage.setItem(
        "userConnexion",
        JSON.stringify({
          ...userConnexion,
          plan: data.plan,
        })
      );
    }
  }, [data]);

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  //Mise a jour des infos de l'utilisateur a chaque changement
  const userInfo = localStorage.getItem("userConnexion");
  if (userInfo) {
    userConnexion = JSON.parse(userInfo);
  } else {
    userConnexion = {
      username: "",
      email: "",
      plan: "",
    };
  }

  if (!isAuthenticated) {
    const token = localStorage.getItem("tokenBotVinted") as string;
    if (token !== null || undefined)
      if (token.length > 0)
        store.dispatch(userActions.updateAuthenticated(true));
  }

  const isAnimating = useAppSelector((state) => state.user.isAnimating);

  let userEndDate: boolean;
  if (data?.endDate !== undefined && data?.endDate !== null) {
    const present_date = new Date();
    userEndDate = new Date(data.endDate).getTime() < present_date.getTime();
  }

  function routes(): JSX.Element {
    if (!isAuthenticated) {
      return (
        <CSSTransition classNames="pageAnimated" timeout={300} in={isAnimating}>
          <Routes>
            <Route
              path={`/${pathGlobal.auth.connexion}`}
              element={<Connexion />}
            />
            <Route
              path={`/${pathGlobal.auth.inscription}`}
              element={<Inscription />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </CSSTransition>
      );
    } else if (data?.plan === "none" || userEndDate) {
      return (
        // <CSSTransition classNames="my-node" timeout={300} in={isAnimating}>
        <Routes>
          <Route path={`/${pathGlobal.url.dashboard}`} element={<Home />}>
            {/* <Route
              path={`/${pathGlobal.url.lien}`}
              element={<LienComposant />}
            />

            <Route
              path={`/${pathGlobal.url.lien}/${pathGlobal.alerte.alerte}`}
              element={<Alerte />}
            />

            <Route
              path={`/${pathGlobal.alerte.alerteTous}`}
              element={<Alerte />}
            /> */}
            <Route
              path={`/${pathGlobal.url.dashboard}`}
              element={<Dashboard />}
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to={`/${pathGlobal.url.dashboard}`} />}
          />
        </Routes>
        // </CSSTransition>
      );
    } else {
      return (
        // <CSSTransition classNames="my-node" timeout={300} in={isAnimating}>
        <Routes>
          <Route path={`/${pathGlobal.url.dashboard}`} element={<Home />}>
            <Route
              path={`/${pathGlobal.url.lien}`}
              element={<LienComposant />}
            />

            <Route
              path={`/${pathGlobal.url.lien}/${pathGlobal.alerte.alerte}`}
              element={<Alerte />}
            />

            <Route
              path={`/${pathGlobal.url.dashboard}`}
              element={<Dashboard />}
            />
            <Route
              path={`/${pathGlobal.alerte.alerteTous}`}
              element={<Alerte />}
            />
            <Route
                path={`/${pathGlobal.faq.faq}`}
                element={<Faq />}
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to={`/${pathGlobal.url.dashboard}`} />}
          />
        </Routes>
        // </CSSTransition>
      );
    }
  }

  return <div>{routes()}</div>;
}

export default App;
