import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  useDeleteUrlMutation,
  useGetUserQuery,
  usePostUrlMutation,
} from "../../api/user/user.api";
import { Loading } from "../Loading";
import EmptyUrl from "./EmptyUrl";
import { urlActions } from "../../redux/slices/urlSlice";
import { useAppSelector } from "../../redux/customHooks";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import ErrorPage from "../../pages/ErrorPage";
import { userConnexion } from "../../App";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useNavigate } from "react-router-dom";
import { pathGlobal } from "../../RouteName";
import { userActions } from "../../redux/slices/userSlice";
import { GiUpgrade } from "react-icons/gi";

// import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

const LienComposant = () => {
  const urlList = useAppSelector((state) => state.url.list);

  const plan = [
    { name: "cancelled", length: 5 },
    { name: "Reseller Débutant", length: 5 },
    { name: "Reseller Confirmé", length: 15 },
    { name: "Crack du Resell", length: 50 },
  ];
  const HandleSubmit = (value: { url: string }) => {
    let url = value.url;
    if (!value.url.includes("order=newest_first")) {
      url += "&order=newest_first";
    }

    !urlList.includes(url)
      ? posturlValue({
          user: userConnexion.username,
          url: url,
        }).then(() => {})
      : Swal.fire({
          text: "Ce lien existe déja",
          icon: "error",
          heightAuto: true,
          background: "#eaecf0",
          timer: timer,
          confirmButtonColor: "#9b51e0",
        });
  };
  const initialValues = {
    url: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      url: yup
        .string()
        .required("L'url est obligatoire")
        .test(
          "lien valide",
          "Vous n'avez pas mis un lien vinted valide ou sans paramètres (catalog[])",
          function (value) {
            let test: boolean = false;
            if (
              value &&
              value.includes("https://www.vinted") &&
              value.includes("catalog[]")
            ) {
              test = true;
            }
            return test;
          }
        ),
      //.url("L'url n'est pas valide"),
    }),
    onSubmit: (values) => {
      HandleSubmit(values);
    },
  });

  const dispatch = useDispatch();
  let timer: number = 3000;
  const [etatLoading, setEtatLoading] = React.useState(false);

  //Post Url
  const [postUrl, { isSuccess, isLoading, error, reset }] =
    usePostUrlMutation();
  //Post Url
  const [
    deleteUrl,
    {
      isSuccess: isSucessDelete,
      isLoading: isLoadingDelete,
      error: errorDelete,
      reset: resetDelete,
    },
  ] = useDeleteUrlMutation();
  // const [user, setUser] = useState<any>();
  const posturlValue = async (value: { user: string; url: string }) => {
    return await postUrl(value);
  };

  useEffect(() => {
    if (isLoading || isLoadingDelete) {
      setEtatLoading(true);
    } else if (isSuccess || isSucessDelete) {
      setEtatLoading(false);
      if (isSuccess) {
        Swal.fire({
          text: "lien ajouté avec sucèes ",
          icon: "success",
          background: "#eaecf0",
          timer: timer,
          confirmButtonColor: "#9b51e0",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        formik.resetForm();
        reset();
      } else if (isSucessDelete) {
        Swal.fire({
          text: "lien supprimé avec sucèes ",
          icon: "success",
          background: "#eaecf0",
          timer: timer,
          confirmButtonColor: "#9b51e0",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        resetDelete();
      }
    } else if (error || errorDelete) {
      setEtatLoading(false);

      if (error) {
        if ("data" in error) {
          let response: any = error.data;
          Swal.fire({
            text: `${response.message}`,
            icon: "error",
            heightAuto: true,
            background: "#eaecf0",
            timer: timer,
            confirmButtonColor: "#9b51e0",
          });
        } else
          Swal.fire({
            text: "Opération échoué Erreur de connexion. Réessayez",
            icon: "error",
            heightAuto: true,
            background: "#eaecf0",
            timer: timer,
            confirmButtonColor: "#9b51e0",
          });

        reset();
      } else if (errorDelete) {
        if ("data" in errorDelete) {
          let response: any = errorDelete.data;
          Swal.fire({
            text: `${response.message}`,
            icon: "error",
            heightAuto: true,
            background: "#eaecf0",
            timer: timer,
            confirmButtonColor: "#9b51e0",
          });
        } else
          Swal.fire({
            text: "Opération échoué Erreur de connexion. Réessayez",
            icon: "error",
            heightAuto: true,
            background: "#eaecf0",
            timer: timer,
            confirmButtonColor: "#9b51e0",
          });

        resetDelete();
      }
    }
  }, [
    isLoading,
    isLoadingDelete,
    isSuccess,
    isSucessDelete,
    error,
    errorDelete,
    reset,
    resetDelete,
    formik,
    timer,
  ]);

  //Get List Url
  const {
    data: dataListUrl,
    error: errorListUrl,
    isLoading: isLoadingListUrl,
    refetch,
  } = useGetUserQuery();

  /*Lors du montage du composant le store de redux opère des actions*/
  useEffect(() => {
    if (dataListUrl !== undefined) {
      dispatch(urlActions.setUrlList(dataListUrl.urls));
      dispatch(
        userActions.setnotificationAlert(dataListUrl.notifications.length !== 0)
      );
      // localStorage.setItem(
      //   "userConnexion",
      //   JSON.stringify({
      //     ...userConnexion,
      //     plan: dataListUrl.plan,
      //   })
      // );
    }
  }, [dataListUrl, dispatch]);

  const displayLien = (): JSX.Element => {
    if (dataListUrl)
      return (
        <>
          {urlList.length === 0 ? (
            <EmptyUrl />
          ) : (
            <div className={"urlListContainer"}>
              {urlList.map((url, key) => {
                return (
                  <div key={key} className={"urlListContent"}>
                    <CopyToClipboard text={url}>
                      <div
                        className="urlLink"
                        onClick={() => {
                          alert("lien copié");
                        }}
                      >
                        {url}
                      </div>
                    </CopyToClipboard>

                    <div className={"iconUrlContainer"}>
                      <Link
                        to={`/${pathGlobal.url.lien}/${pathGlobal.alerte.alerte}`}
                      >
                        <AiFillEye
                          size={25}
                          className={"iconsUrl"}
                          onClick={() => {
                            dispatch(userActions.setUrlClick(url));
                          }}
                        />
                      </Link>
                      <MdDelete
                        size={25}
                        className={"iconsUrl"}
                        onClick={async () => {
                          await deleteUrl({
                            user: userConnexion.username,
                            url: url,
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      );
    else if (isLoadingListUrl) return <Loading />;
    else if (errorListUrl)
      return (
        <ErrorPage message={"...Oups, Il y'a une erreur😥"} refresh={refetch} />
      );
    else return <></>;
  };

  const disabledInput = (): boolean => {
    let checked: boolean = false;
    plan.forEach((item) => {
      if (item.name === userConnexion.plan) {
        if (item.length === urlList.length) checked = true;
      } else if (item.name === "none") {
        checked = true;
      }
    });
    return checked;
  };
  const navigate = useNavigate();
  return (
    <div className={"lienComposantContainer"}>
      <form onSubmit={formik.handleSubmit}>
        <div className="addUrl">
          <input
            type={"url"}
            placeholder={"Saisissez une url"}
            value={formik.values.url}
            name={"url"}
            onChange={formik.handleChange}
            style={{
              borderColor: formik.values.url.length !== 0 ? "#9b51e0" : "",
            }}
            disabled={disabledInput()}
          />
          {!disabledInput() ? (
            <button
              type={"submit"}
              disabled={formik.values.url.length === 0}
              style={
                formik.values.url.length === 0
                  ? {
                      cursor: "not-allowed",
                      background: "rgb(2,2,2,0.2)",
                      color: "rgb(0,0,0,0.2)",
                    }
                  : {
                      cursor: "pointer",
                      background: "#9b51e0",
                      color: "white",
                      transition: "background-color 0.3s linear 0s",
                      boxSizing: "border-box",
                      whiteSpace: "nowrap",
                    }
              }
            >
              <AiOutlinePlus
                size={23}
                style={{
                  marginRight: 10,
                }}
              />
              Ajouter un lien
            </button>
          ) : (
            <button
              style={{
                cursor: "pointer",
                background: "#9b51e0",
                color: "white",
                transition: "background-color 0.3s linear 0s",
                boxSizing: "border-box",
                whiteSpace: "nowrap",
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/${pathGlobal.url.dashboard}`);
              }}
            >
              <GiUpgrade
                size={23}
                style={{
                  marginRight: 10,
                  marginLeft: 5,
                }}
              />
              Passer au plan supérieur
            </button>
          )}
        </div>
        {formik.touched.url && formik.errors.url ? (
          <div className="error-message">{formik.errors.url}</div>
        ) : (
          <div className="error-placeholder" />
        )}
      </form>
      <div className="displayUrlContainer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="title">Liens</div>
          <Link
            to={`/${pathGlobal.alerte.alerteTous}`}
            className="flex items-center justify-center border font-medium text-white shadow-sm hover:bg-violet-900"
            style={{
              fontSize: 17,
              padding: "10px 76px",
              maxWidth: "250px",
              backgroundColor: "#9b51e0",
              cursor: "pointer",
              color: "white",
              transition: "background-color 0.3s linear 0s",
              boxSizing: "border-box",
              whiteSpace: "nowrap",
              borderRadius: 10,
            }}
          >
            <AiFillEye size={23} style={{ marginRight: 10 }} />
            Voir tout
          </Link>
        </div>
        {displayLien()}
      </div>

      {etatLoading && <Loading />}
    </div>
  );
};

export default LienComposant;
