import React from 'react';
import DashboardComponsant from "../../composants/dashboard/DashboardComponsant";

const Dashboard = () => {
    return (
        <div>
            <DashboardComponsant />
        </div>
    );
};

export default Dashboard;